import React from 'react'

import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'
import * as queryString from 'query-string'
import ImageGallery from 'react-image-gallery'
import Layout from '../components/Layout'

export default ({ pageContext, location }) => {
	const { service, services, lang, relatedLangServices } = pageContext
	const currentLocale = useIntl().locale
	const siteUrl = process.env.SITE_URL || 'https://effe2finishing.com'

	// create SEO params for the helmetSeoParams
	const alternateUrls = relatedLangServices.map((service) => ({
		href: `${siteUrl}/${service.lang}/${service.link.replace(/^\/|\/$/g, '')}`,
		lang: service.lang,
	}))
	const canonicalUrl = `${siteUrl}/${service.lang}/${service.link.replace(/^\/|\/$/g, '')}`
	const helmetSeoParams = {
		currentLocale,
		alternateUrls,
		canonicalUrl,
		title: service.seo.title,
		description: service.seo.description,
		keywords: service.seo.keywords,
	}

	const serviceImages = service.gallery.split(',').map((image) => {
		const src = require(`../images/services/${image}`).default
		return {
			original: src,
			thumbnail: src,
		}
	})

	return (
		<Layout location={location} helmetSeoParams={helmetSeoParams}>
			<ul className="nav justify-content-center mb-1">
				{services.map((item) => (
					<li className="nav-item" key={item.id}>
						<Link
							className={`nav-link ${service && service.id === item.id ? 'font-weight-bold' : ''}`}
							to={item.link}>
							{item.title}
						</Link>
					</li>
				))}
			</ul>
			<section className="ftco-section mt-1 pt-4">
				<div className="container">
					{service ? (
						<>
							<div className="row justify-content-center mb-5">
								<div className="col-md-10 text-center heading-section">
									<h2 className="mb-4 text-primary">{service.title}</h2>
									<p>{service.description}</p>
								</div>
								<div className="col-md-10 text-center">
									{service.treatments ? (
										<ul className="list-group list-group-flush">
											<li className="list-group-item font-weight-bold">
												<FormattedMessage id="services.treatments" />
											</li>
											{service.treatments.split(',').map((treatment) => (
												<li className="list-group-item">{treatment}</li>
											))}
										</ul>
									) : null}
								</div>
							</div>

							<div className="row justify-content-center mb-5">
								<ImageGallery items={serviceImages} />
							</div>
						</>
					) : null}
				</div>
			</section>
		</Layout>
	)
}
